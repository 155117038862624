import React, { useEffect } from "react";
import "./App.css";
import Lottie from "react-lottie";
import externalLink from "./images/external-link.svg";
import animationData from "./Lotties/logo_type.json";

const bentoBoxes = document.querySelectorAll(".bentoBox");
const gradient = document.querySelector(".gradient");

bentoBoxes.forEach((box) => {
    box.addEventListener("mouseenter", () => {
        gradient.style.opacity = 1; // Fade in
    });

    box.addEventListener("mouseleave", () => {
        gradient.style.opacity = 0; // Fade out
    });

    window.addEventListener("mousemove", (e) => {
        const x = e.clientX;
        const y = e.clientY;

        console.log(x);
        console.log(y);

        const gradientX = x - 100; // Half of the gradient width
        const gradientY = y - 100; // Half of the gradient height

        gradient.style.left = gradientX + "px";
        gradient.style.top = gradientY + "px";
    });
});

function DynamicSquareDiv() {
    useEffect(() => {
        // Function to make the div a square
        const makeSquare = () => {
            if (window.innerWidth > 768) {
                const dynamicDivs =
                    document.querySelectorAll("[id='dynamicDiv']");
                dynamicDivs.forEach((dynamicDiv) => {
                    const width = dynamicDiv.offsetWidth;
                    dynamicDiv.style.height = width + "px";
                });
            }
        };
        // Attach the event listener to the window resize event
        window.addEventListener("resize", makeSquare);

        // Initial call to makeSquare when the component mounts
        makeSquare();

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", makeSquare);
        };
    }, []); // Empty dependency array ensures this effect runs only once on mount
}

function FadeOnScroll() {
    useEffect(() => {
        // Function to handle scroll event
        const handleScroll = () => {
            // Get the scroll position
            const scrollY = window.scrollY;

            // Get the target element you want to apply the opacity effect to
            const targetElement = document.getElementById("fadeTarget");

            // Calculate opacity based on scroll position (adjust as needed)
            const maxOpacity = 1; // Maximum opacity (fully visible)
            const minOpacity = 0; // Minimum opacity (completely transparent)
            const scrollThreshold = 600; // Adjust this threshold as needed

            // Calculate the opacity based on the scroll position
            const opacity = Math.max(
                maxOpacity -
                    (scrollY / scrollThreshold) * (maxOpacity - minOpacity),
                minOpacity
            );

            // Apply the calculated opacity to the target element
            if (targetElement) {
                targetElement.style.opacity = opacity;
            }
        };

        // Attach the scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
}

function App() {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <>
            <div className="pageContainer">
                <FadeOnScroll />
                <section className="heroSectionContainer" id="fadeTarget">
                    <div className="lottieContainer">
                        <Lottie options={defaultOptions} />
                    </div>
                    <div className="heroDescription">
                        Make the most of your online presence with pro bono web
                        services from the FEFA community
                    </div>
                    <div className="buttons">
                        <div className="ctaContainer">
                            <a href="https://forms.gle/kaV1xTYUsUq7Buuv6">
                                <div className="requestButton button">
                                    Submit a request
                                </div>
                            </a>
                            <div className="buttonDesc">
                                For small businesses + nonprofits
                            </div>
                        </div>
                        <div className="ctaContainer">
                            <a href="https://forms.gle/13ohprEShKeuy9M87">
                                <div className="applyButton button">Apply</div>
                            </a>
                            <div className="buttonDesc">
                                For developers + designers
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="pageContainer">
                <div className="maxWidth">
                    <section className="bentoBoxContainer">
                        {/* First row */}
                        <div className="row" id="flipped">
                            <div className="oneColumn">
                                <DynamicSquareDiv />
                                <a
                                    href="https://www.ktnv.com/positivelylv/las-vegas-town-fridge-helps-feed-those-in-need"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <div
                                        className="bentoBox cream"
                                        id="dynamicDiv"
                                    >
                                        <h2>
                                            “Las Vegas Town Fridge helps feed
                                            those in need”
                                        </h2>
                                        Read about LVTF, FEFA’s first partner
                                        org ––
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/commons/5/54/American_Broadcasting_Company_Logo.svg"
                                            alt="abc news logo"
                                            id="abc-logo"
                                        />
                                    </div>
                                </a>

                                <a href="https://forms.gle/kaV1xTYUsUq7Buuv6">
                                    <div
                                        className="bentoBox green button"
                                        id="dynamicDiv"
                                    >
                                        <h2>Submit a request</h2>
                                        For small businesses and nonprofits
                                        looking to build up/modernize their web
                                        presence
                                        <img
                                            src={externalLink}
                                            alt="external link icon"
                                            width="30"
                                            height="30"
                                        />
                                    </div>
                                </a>
                            </div>

                            <div className="twoColumn">
                                <div className="bentoBox brown">
                                    <h1>About .FEFA</h1>
                                    <div className="row twoColText">
                                        <div className="column">
                                            FEFA started as a way for aspiring
                                            web devs and designers (like
                                            myself!) to learn by doing, and to
                                            hopefully make an impact from the
                                            outset.
                                            <br />
                                            <br />
                                            Like most people first learning to
                                            code, I was told to create some
                                            ‘portfolio projects’––i.e. the
                                            prototypical to-do list app.
                                            <br />
                                            <br />
                                            The issue with projects like these
                                            are 1) everybody does them, so good
                                            luck making your portfolio stand
                                            out, and 2) they’re just not that
                                            interesting!
                                        </div>
                                        <div className="column">
                                            I ended up reaching out to real
                                            people, and offered to build them a
                                            website (for free). I made sure to
                                            set the expectation that I had
                                            absolutely no idea what I was doing,
                                            but that I was committed to learning
                                            and working together.
                                            <br />
                                            <br />
                                            This experience proved to be so
                                            helpful on my path to becoming a
                                            full-fledged web developer. With
                                            FEFA, I want to help facilitate this
                                            kind of experience for other young
                                            developers, and create an
                                            opportunity for experienced
                                            developers to donate their services
                                            to those who need it.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Second row */}
                        <div className="row">
                            <div className="twoColumn">
                                <div className="bentoBox brown">
                                    <div className="row">
                                        <div className="column">
                                            <h2>
                                                Add purpose to your portfolio
                                            </h2>
                                        </div>
                                        <div className="column">
                                            By working with real businesses and
                                            organizations, you’ll be able to add
                                            real-world experience to your
                                            resume, all the while helping those
                                            who could use it No matter your
                                            experience level, FEFA is meant to
                                            be a place where you can learn,
                                            grow, and ultimately make a
                                            difference
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="oneColumn">
                                <div
                                    className="bentoBox brownPurple"
                                    id="dynamicDiv"
                                >
                                    <h2>Become a volunteer</h2>
                                    Whether you’re a developer, designer, or
                                    something in between, we’d be more than
                                    happy to have you
                                    <a href="https://forms.gle/s5ei9xD4ZPPizPyFA">
                                        <div className="applyButton button">
                                            Apply now
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Gradient that follows mouse */}
                    <div className="gradient"></div>
                    {/* Footer */}
                    <div className="footerContainer">
                        <div className="footerContent maxWidth">
                            <div className="contactInfo">
                                Have questions? Get in touch
                                <br />
                                <a href="mailto:contact@fefa.dev">
                                    contact@fefa.dev
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
